<template>
  <div>
   
    <div class="text-center">
         <b-button variant="primary" class="btn btn-primary btn-lg m-5" type="button"  v-on:click="actionPortail('OPEN')"> Ouvrir portail</b-button>
    
        <b-button class="btn btn-danger btn-lg m-5" type="button" v-on:click="actionPortail('CLOSE')"> Fermer portail</b-button>
     </div>
     <div class="text-center" >
        <b-button class="btn btn-secondary btn-lg" style="margin-bottom :20px" type="button" v-on:click="actionPortail('STOP')"> Stop</b-button>
      </div>
      
        <div v-show="showError" class="alert alert-danger" role="alert">Erreur !</div>

        <div class="spinner" v-show="showLoader" >
               <div class="spinner-border" role="status"></div>
        </div>
  </div>

</template>

<script>
import axios from 'axios';
import { required } from "vuelidate/lib/validators";

export default {

data(){
      return{
    
          showError:false,
          showLoader:false
      }
}
,
  validations: {
    form:{
    }
  }

,
 methods:
 {
   
   clear()
    {
      this.showError=false;
      this.showLoader=false;
    },
    alertSucces() {
        // $swal function calls SweetAlert into the application with the specified configuration.
        this.$swal({html: '<div class="alert alert-success" role="alert"> C\'est parti chef !</div>',  
                      showConfirmButton: false,
                      padding: "0",
                      position:"center",
                      timer: 1500
                   });
      }
      ,
    actionPortail(action)
    {
      
      var that=this;
      that.showLoader=true;
    
    axios.get('https://tahoma-api.azurewebsites.net/api/portal/action?action='+ action)
      .then(function (response) {
        that.code=response.data;

        if (that.length==0)
        {
          that.showError=true;
        }
        else
        {
          that.showError=false;    
          that.alertSucces();
        }

          that.showLoader=false;
    })
    .catch(function (error) {
      console.log(error);
      that.showLoader=false;
    });
    
     
  }
}

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
