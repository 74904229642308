<template>
  <div class="Home">
    <div class="text-center">
        <h1 >{{ msg }}</h1>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String
  }
}
</script>

