<template>
   <b-container>
    <div class="rounded" style="background: lightGrey;margin:5%">
     <Home msg="Tahoma Engine"/>
    <Tahoma></Tahoma>
  </div>
  </b-container>
</template>


<script>
import Home from './components/Home.vue'
import Tahoma from './components/Tahoma.vue'

export default {
  name: 'App',
  components: {
    Home,
    Tahoma
  }
}
</script>

<style>
body,
html {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
</style>
