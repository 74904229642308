import Vue from 'vue'

import Vuelidate from 'vuelidate'
import VuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";
import FormGroup from "../components/FormGroup.vue";

Vue.use(Vuelidate)
Vue.use(VuelidateErrorExtractor, {
   
    // Define common validation messages.
    messages: {
      required: "{attribute} est obligatoire!",
      notGmail: "{attribute} must not be gmail",
      email: "{attribute} is not a valid Email address.",
      isEmailAvailable:
        "{attribute} is not available. Must be at least 10 characters long."
    }
  });
  
Vue.component("FormGroup", FormGroup);
Vue.component("formWrapper", templates.FormWrapper);

